import React from 'react'
import Header from '../Generic/Header'
import ChannelTable from './ChannelTable'

import './Channel.css'

const Channel = () => {
  return (
    <>
      <Header
        heading='Channel'
        link='/directory/221133/dashboard/channels/add'
        add_btn={true}
        add_btn_text='Add Channel'
      />
      <div style={{ marginTop: '2rem' }}>
        <ChannelTable />
      </div>
    </>
  )
}

export default Channel
