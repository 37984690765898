import React, { useState } from 'react'
import Header from '../Generic/Header'
import { Form, Input, Button } from 'antd'
import { useGetChannelHtmlMutation } from '../../services/nodeApi'
const Scrapping = () => {
  const [getChannelHtml] = useGetChannelHtmlMutation()
  const [htmlResult, setHtmlResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const onFinish = async values => {
    setLoading(true)
    const res = await getChannelHtml(values)
    if (res.data && res.data.status === 'success') {
      setHtmlResult(res.data.data)
      setLoading(false)
    } else {
      setHtmlResult(null)
      setLoading(false)
    }
  }
  const onFinishFailed = errorInfo => {
    setHtmlResult(null)
    setLoading(false)
  }

  return (
    <>
      <Header heading='Scrapping' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        <Form
          size='large'
          name='basic'
          layout='inline'
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Website Url'
            name='url'
            rules={[
              {
                required: true,
                message: 'Please input website link!'
              }
            ]}
            style={{ width: '43.5%' }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='HTML Tag'
            name='tag'
            style={{ width: '43.5%' }}
            rules={[
              {
                required: true,
                message: 'Please input website header!'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type='primary' loading={loading} htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
        {htmlResult && (
          <div
            style={{
              marginTop: '2rem'
            }}
          >
            <div
              style={{
                maxHeight: '60vh',
                overflowY: 'scroll',
                border: '1px solid #dcdcdc',
                borderRadius: '10px',
                padding: '2rem',
                background: 'rgb(250,250,250)'
              }}
            >
              {htmlResult
                ? htmlResult.map((el, i) => <li key={i}>{el}</li>)
                : ''}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Scrapping
