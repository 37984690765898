import React, { useState, useEffect } from 'react'
import {
  useAddChannelMutation,
  useGetAllChannelsQuery,
  useGetAllCountriesQuery
} from '../../services/nodeApi'
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Space,
  message,
  Switch
} from 'antd'
import ImgCrop from 'antd-img-crop'
import Header from '../Generic/Header'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
const { Option } = Select

export default function AddChannel () {
  let navigate = useNavigate()
  const [addChannel] = useAddChannelMutation()
  const { data: countriesData } = useGetAllCountriesQuery()
  const { data: channelsData } = useGetAllChannelsQuery()
  const [countries, setCountries] = useState(null)
  const [categories, setCategories] = useState(null)
  const [priorities, setPriorities] = useState(null)
  const [_hide, setHide] = useState(false)
  const [streamingLinksCount, setStreamingLinksCount] = useState(0)
  const [fileList, setFileList] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  const handleChange = newTags => {
    setTags(newTags)
  }
  const [form] = Form.useForm()

  const onFinish = async values => {
    if (!fileList[0]) {
      message.error('Please select image!')
    } else {
      let formData = new FormData()
      formData.append('name', values.name)
      if (values.streamingLinks)
        formData.append('streamingLinks', JSON.stringify(values.streamingLinks))
      formData.append('category', values.category)
      formData.append('image', fileList[0].originFileObj)
      formData.append('country', values.country)
      formData.append('hide', _hide)
      setLoading(true)
      const res = await addChannel(formData)
      setFileList([])
      console.log(res)
      if (res.data && res.data.status === 'success') {
        form.resetFields()
        setLoading(false)
        message.success('Channel has been added!')
        setTimeout( () => navigate( '/directory/221133/dashboard/channels' ), 1000 )
      } else {
        setLoading(false)
        message.error('Something went wrong!, Please try again later')
      }
    }
  }
  useEffect(() => {
    let _Countries = []
    countriesData &&
      countriesData.map(el => {
        return _Countries.push({
          value: el.name.toLowerCase(),
          label: el.name
        })
      })
    setCountries(_Countries)
  }, [countriesData])

  useEffect(() => {
    let _categories = []
    channelsData &&
      channelsData.data.map(el => {
        return _categories.push(el.category)
      })
    _categories = Array.from(new Set(_categories))
    setCategories(_categories)
  }, [channelsData])
  useEffect(() => {
    let arr = []
    for (let index = 0; index < streamingLinksCount; index++) {
      arr.push(index + 1)
    }
    setPriorities(arr)
  }, [streamingLinksCount])

  const onImgChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }
  const onPreview = async file => {
    let src = file.url
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }
  return (
    <>
      <Header heading='Add Channel' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        {countries && categories && (
          <Form
            name='add_channel_form'
            form={form}
            layout='horizontal'
            onFinish={onFinish}
          >
            <Form.Item label='Hide Channel:' hasFeedback>
              <Switch onChange={val => setHide(val)} />
            </Form.Item>
            <Form.Item
              name='name'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input channel name!'
                }
              ]}
            >
              <Input placeholder='Channel name' />
            </Form.Item>
            <Form.Item
              name='country'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please select country!'
                }
              ]}
            >
              <Select showSearch placeholder='Select country'>
                {countries.map((el, i) => (
                  <Option key={i} value={el.value}>
                    {el.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Form.Item
                name='category'
                style={{ width: '96%' }}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please select category!'
                  }
                ]}
              >
                <Select
                  showSearch
                  mode='tags'
                  value={tags}
                  onChange={handleChange}
                  placeholder='Select category'
                  disabled={tags.length}
                  options={categories.map((el, i) => ({
                    label: el,
                    value: el
                  }))}
                />
              </Form.Item>
              <Button
                onClick={() => {
                  setTags([])
                  form.resetFields(['category'])
                }}
              >
                Clear
              </Button>
            </div>
            <Form.List name='streamingLinks'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8
                      }}
                      align='baseline'
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        style={{ width: '250px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input streaming link name!'
                          }
                        ]}
                      >
                        <Input placeholder='Name' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'url']}
                        style={{ width: '250px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input streaming link url!'
                          }
                        ]}
                      >
                        <Input placeholder='Url' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'token']}
                        style={{ width: '250px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input token!'
                          }
                        ]}
                      >
                        <Input placeholder='Token' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'priority']}
                        style={{ width: '150px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input priority!'
                          }
                        ]}
                      >
                        <Select placeholder='Select priority'>
                          {priorities.map((el, i) => (
                            <Option key={i} value={el}>
                              {el}
                            </Option>
                          ))}
                        </Select>
                        {/* <Input type="number" placeholder="Priority" /> */}
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'request_header']}
                        style={{ width: '250px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input request header!'
                          }
                        ]}
                      >
                        <Input placeholder='Request Header' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'player_header']}
                        style={{ width: '250px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input player header!'
                          }
                        ]}
                      >
                        <Input placeholder='Player Header' />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          setStreamingLinksCount(prev => prev - 1)
                          remove(name)
                        }}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      style={{ backgroundColor: 'rgb(240,240,240' }}
                      onClick={() => {
                        setStreamingLinksCount(prev => prev + 1)
                        add()
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Streaming link
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <ImgCrop rotate>
              <Upload
                listType='picture-card'
                fileList={fileList}
                onChange={onImgChange}
                onPreview={onPreview}
                beforeUpload={() => false}
              >
                {fileList.length < 1 && '+ Channel image'}
              </Upload>
            </ImgCrop>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  paddingTop: '1.5rem'
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  loading={loading}
                >
                  Add
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  )
}
