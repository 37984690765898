import React from 'react'
import './User.css'
import Header from '../Generic/Header'
import UserTable from './UserTable'

const User = () => {
  return (
    <>
      <Header heading='Users' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        <UserTable />
      </div>
    </>
  )
}

export default User
