import React, { useState, useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useGetAllEventCountriesQuery } from '../../services/nodeApi'
import { Link } from 'react-router-dom'
import './../User/User.css'
import Header from '../Generic/Header'
import { setEventCountriesId } from '../../redux/eventCountriesSlice'

const EventCountries = () => {
  return (
    <>
      <Header heading='Event Countries' />
      <div style={{ marginTop: '2rem' }}>
        <CountriesTable />
      </div>
    </>
  )
}

const CountriesTable = () => {
  const dispatch = useDispatch()
  const { data, isLoading, isFetching } = useGetAllEventCountriesQuery()
  const [countriesData, setCountriesData] = useState(null)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: '20%'
    },

    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      width: '40%'
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '40%',
      render: (_, record) => (
        <>
          <Tooltip title='Edit token'>
            <Link to={`/directory/221133/dashboard/event/countries/update`}>
              <span
                onClick={() => {
                  dispatch(setEventCountriesId(record._id))
                }}
                style={{
                  fontSize: '1.3rem',
                  color: '#2596be',
                  cursor: 'pointer',
                  marginRight: '14px'
                }}
              >
                <EditOutlined />
              </span>
            </Link>
          </Tooltip>
        </>
      )
    }
  ]

  useEffect(() => {
    if (!isLoading && data && !isFetching) {
      setCountriesData(data.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])
  useEffect(() => {}, [data])

  return (
    <>
      {!isLoading && columns && (
        <Table columns={columns} dataSource={countriesData} size='middle' />
      )}
    </>
  )
}

export default EventCountries
