import React from 'react'
import Header from '../Generic/Header'
import EventTable from './EventTable'

import './../Channel/Channel.css'

const Event = () => {
  return (
    <>
      <Header
        heading='Events'
        link='/directory/221133/dashboard/events/add'
        add_btn={true}
        add_btn_text='Add Event'
      />
      <div style={{ marginTop: '2rem' }}>
        <EventTable />
      </div>
    </>
  )
}

export default Event
