import React, { useState, useEffect } from 'react'
import {
  Avatar,
  Table,
  Button,
  Input,
  Row,
  Col,
  Modal,
  Collapse,
  Descriptions,
  Tooltip,
  message
} from 'antd'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  EditOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { setChannelId } from '../../redux/channelSlice'
import {
  useGetAllChannelsQuery,
  useDeleteChannelMutation
} from '../../services/nodeApi'
import { Link } from 'react-router-dom'
const { Search } = Input
const { confirm } = Modal
const { Panel } = Collapse

const ChannelTable = () => {
  const dispatch = useDispatch()
  const [deleteChannel] = useDeleteChannelMutation()
  const { data, isLoading, isFetching } = useGetAllChannelsQuery()
  const [filteredInfo, setFilteredInfo] = useState({})
  const [searchedText, setSearchedText] = useState('')
  const [channelData, setChannelData] = useState(null)
  const [columns, setColumns] = useState(null)
  const [modalContent, setModalContent] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const success = msg => {
    messageApi.open({
      type: 'success',
      content: msg
    })
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showDeleteConfirm = record => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you want to delete this channel?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk () {
        await deleteChannel(record.key)
        setTimeout(() => {
          success('Channel has been deleted!')
        }, 1500)
      },
      onCancel () {}
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
  }
  const clearFilters = () => {
    setFilteredInfo({})
  }

  const onSearch = e => {
    setSearchedText(e.target.value)
  }

  useEffect(() => {
    if (!isLoading && data && !isFetching) {
      const _categories = []
      setTimeout(
        () =>
          setChannelData(
            data.data.map((el, i) => {
              if (!_categories.some(e => e.text === el.category)) {
                _categories.push({ text: el.category, value: el.category })
              }
              return {
                id: el.ID,
                name: el.name,
                _streamingLinks: JSON.stringify(el.streamingLinks),
                category: el.category,
                country: el.country,
                hide: el.hide ? 'true' : 'false',
                image: (
                  <Avatar src={`${el.imageUrl}`} shape='square' size={130} />
                ),
                key: el._id
              }
            }).sort((a,b)=> Number(a.id)-Number(b.id))
          ),
        1500
      )
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          align: 'center',
          filteredValue: [searchedText],
          onFilter: (value, record) => {
            return record.name.toLowerCase().includes(value.toLowerCase())
          }
        },
        {
          title: 'Category',
          dataIndex: 'category',
          filters: _categories,
          filteredValue: filteredInfo.category || null,
          onFilter: (value, record) => {
            return record.category.includes(value)
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Country',
          dataIndex: 'country',
          align: 'center'
        },
        {
          title: 'Hidden',
          dataIndex: 'hide',
          align: 'center'
        },
        {
          title: 'Image',
          dataIndex: 'image',
          align: 'center'
        },
        {
          title: 'Streaming Links',
          dataIndex: 'streamingLinks',
          align: 'center',
          render: (_, record) => (
            <>
              <Tooltip title='View all streaming links'>
                <span
                  onClick={() => {
                    let data = JSON.parse(record._streamingLinks)
                    data = data.sort((a, b) => a.priority - b.priority)
                    setModalContent(data)
                    showModal(true)
                  }}
                  style={{
                    fontSize: '1.3rem',
                    color: '#45bf55',
                    cursor: 'pointer'
                  }}
                >
                  <EyeOutlined />
                </span>
              </Tooltip>
            </>
          )
        },
        {
          title: 'Actions',
          key: 'action',
          align: 'center',
          render: (_, record) => (
            <>
              <Tooltip title='Edit channel'>
                <Link to={`/directory/221133/dashboard/channels/update`}>
                  <span
                    onClick={() => dispatch(setChannelId(record.key))}
                    style={{
                      fontSize: '1.3rem',
                      color: '#2596be',
                      cursor: 'pointer',
                      marginRight: '14px'
                    }}
                  >
                    <EditOutlined />
                  </span>
                </Link>
              </Tooltip>
              <Tooltip title='Delete channel'>
                <span
                  onClick={() => showDeleteConfirm(record)}
                  style={{
                    fontSize: '1.3rem',
                    color: 'red',
                    cursor: 'pointer'
                  }}
                >
                  <DeleteOutlined />
                </span>
              </Tooltip>
            </>
          )
        }
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, filteredInfo, data, searchedText])
  useEffect(() => {}, [data])

  return (
    <>
      {contextHolder}
      <Row style={{ marginBottom: 10 }}>
        <Col span={2}>
          <Button onClick={clearFilters}>Clear all filters</Button>
        </Col>
        <Col span={16}></Col>
        <Col span={6}>
          <Search
            size='large'
            placeholder='Start searching by name...'
            enterButton
            onChange={onSearch}
          />
        </Col>
      </Row>
      {!isLoading && columns && (
        <Table
          columns={columns}
          onChange={handleTableChange}
          dataSource={channelData}
          size='middle'
        />
      )}
      {modalContent && (
        <Modal
          width={1000}
          title='Streaming Links'
          footer={null}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Collapse>
            {modalContent.map((el, i) => {
              return (
                <Panel header={el.name} key={i}>
                  <Descriptions layout='vertical' bordered size='middle'>
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 'bold' }}
                      style={{ textAlign: 'center' }}
                      label='Url'
                    >
                      {el.url}
                    </Descriptions.Item>
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 'bold' }}
                      style={{ textAlign: 'center' }}
                      label='Priority'
                    >
                      {el.priority}
                    </Descriptions.Item>
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 'bold' }}
                      style={{ textAlign: 'center' }}
                      label='Token'
                    >
                      {el.token}
                    </Descriptions.Item>
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 'bold' }}
                      style={{ textAlign: 'center' }}
                      label='Request Header'
                    >
                      {el.request_header}
                    </Descriptions.Item>
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 'bold' }}
                      style={{ textAlign: 'center' }}
                      label='Player Header'
                    >
                      {el.player_header}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              )
            })}
          </Collapse>
        </Modal>
      )}
    </>
  )
}

export default ChannelTable
