import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

// const baseUrl='http://localhost:3001/v1'
const baseUrl='http://api.multistreamz.com/v1'

export const nodeApi = createApi({
  reducerPath: 'nodeApi',
  baseQuery: fetchBaseQuery({ baseUrl }),

  tagTypes: ['Users', 'Channel', 'Event', 'Token'],

  endpoints: builder => ({
    getAllCountries: builder.query({
      query: () => ({
        url: 'https://restcountries.com/v2/all',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      })
    }),
    //********** Login query
    login: builder.mutation({
      query: body => ({
        url: '/users/login',
        method: 'POST',
        body
      })
      // invalidatesTags: [ 'User' ],
    }),
    //******** Get All users
    getAllDeviceIds: builder.query({
      query: () => ({
        url: '/devices/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Users']
    }),
    deleteDeviceId: builder.mutation({
      query: id => ({
        url: `/devices/${id}`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Users']
    }),
    getAllChannelCountries: builder.query({
      query: () => ({
        url: '/channel/countries/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    getAllEventCountries: builder.query({
      query: () => ({
        url: '/event/countries/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    getAllChannelCategories: builder.query({
      query: () => ({
        url: '/channel/categories/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    getChannelCategoriesById: builder.query({
      query: channelId => ({
        url: `/channel/categories/${channelId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    updateChannelCategories: builder.mutation({
      query: body => ({
        url: `/channel/categories/${body.channelCategoriesId}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Channel']
    }),
    getAllEventCategories: builder.query({
      query: () => ({
        url: '/event/categories/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    getEventCategoriesById: builder.query({
      query: eventId => ({
        url: `/event/categories/${eventId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    updateEventCategories: builder.mutation({
      query: body => ({
        url: `/channel/categories/${body.eventCategoriesId}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Event']
    }),
    getEventCountriesById: builder.query({
      query: eventId => ({
        url: `/event/countries/${eventId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    updateEventCountries: builder.mutation({
      query: body => ({
        url: `/event/countries/${body.eventCountriesId}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Event']
    }),
    getChannelCountriesById: builder.query({
      query: channelId => ({
        url: `/channel/countries/${channelId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    updateChannelCountries: builder.mutation({
      query: body => ({
        url: `/channel/countries/${body.channelCountriesId}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Channel']
    }),
    getAllTokens: builder.query({
      query: () => ({
        url: '/token/all/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Token']
    }),
    getTokenById: builder.query({
      query: tokenId => ({
        url: `/token/${tokenId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    addToken: builder.mutation({
      query: formData => ({
        url: `/token`,
        method: 'POST',
        body: formData,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Token']
    }),
    updateToken: builder.mutation({
      query: body => ({
        url: `/token/${body.tokenId}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Token']
    }),
    deleteToken: builder.mutation({
      query: id => ({
        url: `/token/${id}`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Token']
    }),
    //******** Get All Channels
    getAllChannels: builder.query({
      query: () => ({
        url: '/channel/all/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    //******** Get All Events
    getAllEvents: builder.query({
      query: () => ({
        url: '/event/all/',
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    //******** Logout
    logoutUser: builder.mutation({
      query: body => ({
        url: `/users/logout`,
        method: 'POST',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),

      invalidatesTags: ['Users']
    }),
    //******** Add channel
    addChannel: builder.mutation({
      query: formData => ({
        url: `/channel`,
        method: 'POST',
        body: formData,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Channel']
    }),
    //******** Add event
    addEvent: builder.mutation({
      query: formData => ({
        url: `/event`,
        method: 'POST',
        body: formData,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Event']
    }),
    // get channel by id
    getChannelById: builder.query({
      query: channelId => ({
        url: `/channel/${channelId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Channel']
    }),
    // get event by id
    getEventById: builder.query({
      query: eventId => ({
        url: `/event/${eventId}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      providesTags: ['Event']
    }),
    //******** Update channel
    updateChannel: builder.mutation({
      query: body => ({
        url: `/channel/${body.get('id')}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Channel']
    }),
    //******** Update event
    updateEvent: builder.mutation({
      query: body => ({
        url: `/event/${body.get('id')}`,
        method: 'PATCH',
        body,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Event']
    }),
    //******** Web Scrapper
    getChannelHtml: builder.mutation({
      query: data => ({
        url: `/channel/scrap`,
        method: 'POST',
        body: data,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      })
    }),
    deleteChannel: builder.mutation({
      query: id => ({
        url: `/channel/${id}`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Channel']
    }),
    deleteEvent: builder.mutation({
      query: id => ({
        url: `/event/${id}`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`
        }
      }),
      invalidatesTags: ['Event']
    }),
    //******** Apk Versioning
    createVersion: builder.mutation({
      query: data => ({
        url: `/versions`,
        method: 'POST',
        body: data,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`,
        }
      }),
    }),
    getVersions: builder.mutation({
      query: (params) => ({
        url: `/versions`,
        method: 'GET',
        params: params,
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`,
        }
      }),
    }),
    deleteVersion: builder.mutation({
      query: (id) => ({
        url: `/versions`,
        params: {"id": id},
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`,
        }
      }),
    }),
    updatePassword: builder.mutation({
      query: (id,body) => ({
        url: `/users/password/${id}`,
        body: body,
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${Cookies.get('jwt')}`,
        }
      })
    })
  })
})

export const {
  useGetChannelCountriesByIdQuery,
  useGetEventCountriesByIdQuery,
  useUpdateChannelCountriesMutation,
  useUpdateEventCountriesMutation,
  useGetAllEventCategoriesQuery,
  useGetAllChannelCountriesQuery,
  useGetAllEventCountriesQuery,
  useGetEventCategoriesByIdQuery,
  useUpdateEventCategoriesMutation,
  useLoginMutation,
  useGetAllChannelsQuery,
  useGetAllEventsQuery,
  useLogoutUserMutation,
  useGetChannelByIdQuery,
  useGetEventByIdQuery,
  useGetAllDeviceIdsQuery,
  useGetChannelHtmlMutation,
  useAddChannelMutation,
  useAddEventMutation,
  useDeleteChannelMutation,
  useDeleteEventMutation,
  useDeleteDeviceIdMutation,
  useGetAllCountriesQuery,
  useUpdateChannelMutation,
  useUpdateEventMutation,
  useAddTokenMutation,
  useDeleteTokenMutation,
  useUpdateTokenMutation,
  useGetAllTokensQuery,
  useGetTokenByIdQuery,
  useGetAllChannelCategoriesQuery,
  useUpdateChannelCategoriesMutation,
  useGetChannelCategoriesByIdQuery,
  useCreateVersionMutation,
  useGetVersionsMutation,
  useDeleteVersionMutation,
  useUpdatePasswordMutation,
} = nodeApi
