import React from 'react'
import './../User/User.css'
import Header from '../Generic/Header'
import TokenTable from './TokenTable'

const Token = () => {
  return (
    <>
      <Header
        heading='Tokens'
        add_btn={true}
        add_btn_text={'Add Token'}
        link={'/directory/221133/dashboard/tokens/add'}
      />
      <div style={{ marginTop: '2rem' }}>
        <TokenTable />
      </div>
    </>
  )
}

export default Token
