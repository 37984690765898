import { createSlice } from '@reduxjs/toolkit'

//******* Declare your state variable here
const initialState = {
  channelId: null
}

export const channelSlice = createSlice({
  name: 'channelId',
  initialState,
  reducers: {
    setChannelId: (state, action) => {
      state.channelId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setChannelId } = channelSlice.actions

export default channelSlice.reducer
