import { createSlice } from '@reduxjs/toolkit'

//******* Declare your state variable here
const initialState = {
  eventId: null
}

export const eventSlice = createSlice({
  name: 'eventId',
  initialState,
  reducers: {
    setEventId: (state, action) => {
      state.eventId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setEventId } = eventSlice.actions

export default eventSlice.reducer
