import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import Header from '../Generic/Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useGetChannelCategoriesByIdQuery,
  useUpdateChannelCategoriesMutation
} from '../../services/nodeApi'

const UpdateChannelCategories = () => {
  const { channelCategoriesId } = useSelector(
    state => state.channelCategoriesId
  )
  const [channelCategoriesData, setChannelCategoriesData] = useState(null)
  let navigate = useNavigate()
  const [updateChannelCategories] = useUpdateChannelCategoriesMutation()
  const { data } = useGetChannelCategoriesByIdQuery(channelCategoriesId)
  const [form] = Form.useForm()

  const onFinish = async values => {
    let obj = { ...values, channelCategoriesId: channelCategoriesId }
    const res = await updateChannelCategories(obj)
    if (res.data && res.data.status === 'success') {
      message.success('Channel Category has been updated!')
      setTimeout( () => navigate( '/directory/221133/dashboard/channel/categories' ), 1000 )
    } else {
      message.error('Something went wrong!, Please try again later')
    }
  }
  useEffect(() => {
    data && setChannelCategoriesData(data.data)
  }, [data])

  return (
    <>
      <Header heading='Edit Channel Category' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        {channelCategoriesData && (
          <Form
            name='update_channel_Categories_form'
            form={form}
            layout='horizontal'
            onFinish={onFinish}
            initialValues={{
              ID: channelCategoriesData.ID,
              name: channelCategoriesData.name
            }}
          >
            <Form.Item
              name='ID'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input channel category id!'
                }
              ]}
            >
              <Input type='Number' placeholder='Channel category id' />
            </Form.Item>
            <Form.Item
              name='name'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input channel category  name!'
                }
              ]}
            >
              <Input placeholder='Channel category  name' />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  paddingTop: '1.5rem'
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  Edit
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  )
}

export default UpdateChannelCategories
