import React, { useState, useEffect } from 'react'
import { Table, Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  useGetAllDeviceIdsQuery,
  useDeleteDeviceIdMutation
} from '../../services/nodeApi'
const { confirm } = Modal

const UserTable = () => {
  const { data, isLoading } = useGetAllDeviceIdsQuery()
  const [deleteDeviceId] = useDeleteDeviceIdMutation()
  const [deviceData, setDevicedata] = useState(null)

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center'
    },
    {
      title: 'Device IDs',
      dataIndex: 'deviceids',
      align: 'center'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => (
        <span
          onClick={() => showDeleteConfirm(record)}
          style={{ fontSize: '1.3rem', color: 'red', cursor: 'pointer' }}
        >
          <DeleteOutlined />
        </span>
      )
    }
  ]

  const showDeleteConfirm = record => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you want to delete this device id?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk () {
        await deleteDeviceId(record.key)
      },
      onCancel () {}
    })
  }

  useEffect(() => {
    if ( !isLoading&&data ) {
      let _data = data.data.map((device, i) => {
        return {
          key: device._id,
          no: i + 1,
          deviceids: device.device_id
        }
      })
      setDevicedata(_data)
    }
  }, [data, isLoading])


  return (
    <>
      <div className='user_table'>
        {deviceData && (
          <Table columns={columns} dataSource={deviceData} size='middle' />
        )}
      </div>
    </>
  )
}

export default UserTable
