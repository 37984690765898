import React from 'react'
import Cookies from 'js-cookie'
import './Dashboard.css'
import {
  UserOutlined,
  LogoutOutlined,
  VideoCameraOutlined,
  SearchOutlined,
  CalendarOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Dropdown, Collapse } from 'antd'
const { Panel } = Collapse

const Dashboard = () => {
  const location = useLocation()
  let navigate = useNavigate()
  const handleLogout = e => {
    e.preventDefault()
    Cookies.remove('jwt')
    navigate( '/directory/221133/login' )
  }
  const items = [
    {
      key: '1',
      label: (
        <span onClick={handleLogout}>
          <LogoutOutlined />
          &nbsp;Logout
        </span>
      )
    },
  ]

  return (
    <>
      <div style={{ background: 'rgb(245,245,245)' }}>
        
        <div className='main'>
          <div className='left-bar'>
            <div
              style={{
                padding: '3rem 0rem',
                fontSize: '2rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Dashboard
            </div>
            <div className='left-content'>
              <ul className='action-list'>
                <li className='item'>
                  <Link
                    to='/directory/221133/dashboard/users'
                    style={{ textDecoration: 'none', color: 'black' }}
                    className={`${
                      location.pathname.endsWith('users') ||
                      location.pathname.endsWith('rd') ||
                      location.pathname.endsWith('/')
                        ? 'side_active'
                        : ''
                    }`}
                  >
                    <UserOutlined />
                    Users
                  </Link>
                </li>
                <li className='item'>
                  <Link
                    to='/directory/221133/dashboard/tokens'
                    style={{ textDecoration: 'none', color: 'black' }}
                    className={`${
                      location.pathname.endsWith('tokens') ||
                      location.pathname.includes('tokens')
                        ? 'side_active'
                        : ''
                    }`}
                  >
                    <FileTextOutlined /> Tokens
                  </Link>
                </li>

                <li className='item'>
                  <Collapse ghost expandIconPosition='end'>
                    <Panel
                      header={
                        <>
                          <span style={{ fontSize: '1rem' }}>
                            <VideoCameraOutlined />
                            Channels
                          </span>
                        </>
                      }
                    >
                      <ul>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/channels'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith('channels') ||
                              location.pathname.includes('channels')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            View All Channels
                          </Link>
                        </li>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/channel/countries'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith('channel/countries') ||
                              location.pathname.includes('channel/countries')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            Channel Countries
                          </Link>
                        </li>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/channel/categories'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith(
                                'channel/categories'
                              ) ||
                              location.pathname.includes('channel/categories')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            Channel Categories
                          </Link>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </li>
                <li className='item'>
                  <Collapse ghost expandIconPosition='end'>
                    <Panel
                      header={
                        <>
                          <span style={{ fontSize: '1rem' }}>
                            <CalendarOutlined />
                            Events
                          </span>
                        </>
                      }
                      key='1'
                    >
                      <ul>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/events'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith('events') ||
                              location.pathname.includes('events')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            View All Events
                          </Link>
                        </li>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/event/countries'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith('event/countries') ||
                              location.pathname.includes('event/countries')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            Event Countries
                          </Link>
                        </li>
                        <li className='nested_item'>
                          <Link
                            to='/directory/221133/dashboard/event/categories'
                            style={{ textDecoration: 'none', color: 'black' }}
                            className={`${
                              location.pathname.endsWith('event/categories') ||
                              location.pathname.includes('event/categories')
                                ? 'nested_side_active'
                                : ''
                            }`}
                          >
                            Event Categories
                          </Link>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </li>

                <li className='item'>
                  <Link
                    to='/directory/221133/dashboard/scrapping'
                    style={{ textDecoration: 'none', color: 'black' }}
                    className={`${
                      location.pathname.endsWith('scrapping')
                        ? 'side_active'
                        : ''
                    }`}
                  >
                    <SearchOutlined /> Scrapping
                  </Link>
                </li>
                
                <li className='item'>
                  <Link
                    to='/directory/221133/dashboard/versions'
                    style={{ textDecoration: 'none', color: 'black' }}
                    className={`${
                      location.pathname.endsWith('versions')
                        ? 'side_active'
                        : ''
                    }`}
                  >
                    <CloudUploadOutlined /> Versions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='page-content'>
          <div className='header_main'>
          <span style={{ fontWeight: 'bold' }}>Welcome, Admin</span>
          <div>
            <Dropdown
              menu={{
                items
              }}
              placement='bottom'
            >
              <Avatar size='medium' icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
