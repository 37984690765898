import { createSlice } from '@reduxjs/toolkit'

//******* Declare your state variable here
const initialState = {
  eventCountriesId: null
}

export const eventCountriesSlice = createSlice({
  name: 'eventCountriesId',
  initialState,
  reducers: {
    setEventCountriesId: (state, action) => {
      state.eventCountriesId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setEventCountriesId } = eventCountriesSlice.actions

export default eventCountriesSlice.reducer
