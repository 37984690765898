import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { Button, Form, Input, Tooltip } from 'antd'
import { message } from 'antd'
import './Login.css'
import { useLoginMutation } from '../../services/nodeApi'
import Cookie from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import {
  MailOutlined,
  EyeOutlined,
  ArrowRightOutlined
} from '@ant-design/icons'

export default function Login () {
  const [login] = useLoginMutation()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    try {
      setLoading(true)
      const res = await login(values)
      if (res.data?.status === 'success') {
        setLoading(false)
        console.log(res.data?.data)
        localStorage.setItem("user_id", res.data?.data?.user?.id)
        // if (res.data.data.user.role === 'admin') {
        message.success('Logged in sucessfully')
        Cookie.set('jwt', res.data.token)
        setTimeout(() => {
          navigate( '/directory/221133/dashboard' )
        }, 2000)
        // }
      } else if (
        res.error.data.message.includes('Incorrect email or password')
      ) {
        setLoading(false)
        message.error(res.error.data.message)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  const onFinishFailed = errorInfo => {
    message.error('Login failed')
  }

  return (
    <div className='auth'>
      <Row>
        <Col span={13}>
          <div style={{ height: '100vh' }}>
            <img
              className='login_img'
              src={require('./../../login.png')}
              alt='svg'
            />
          </div>
        </Col>
        <Col span={11}>
          <div className='login_form'>
            <div
              style={{
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  marginBottom: 45
                }}
              >
                TvChannel Login
              </p>
              <Form
                name='basic'
                initialValues={{
                  remember: true
                }}
                labelCol={{
                  span: 8
                }}
                wrapperCol={{
                  span: 24
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name='email'
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please enter your email'
                    }
                  ]}
                >
                  <Input
                    placeholder='Email'
                    prefix={<MailOutlined />}
                    className='input_email_icon'
                    style={{
                      borderRadius: '30px',
                      padding: '8px 8px 8px 15px',
                      background: 'rgb(242, 242, 242)'
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name='password'
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your passowrd'
                    }
                  ]}
                >
                  <Input.Password
                    placeholder='Password'
                    className='input_password_icon'
                    prefix={<EyeOutlined />}
                    style={{
                      borderRadius: '30px',
                      padding: '8px 8px 8px 15px',
                      background: 'rgb(242, 242, 242)'
                    }}
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: 'center' }}>
                  <Button
                    htmlType='submit'
                    style={{
                      width: '100%',
                      height: '2.6rem',
                      borderRadius: '30px',
                      background: '#e4b303',
                      fontWeight: 'bold'
                    }}
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
