import React, { useEffect, useState } from 'react';
import './Version.css';
import { useDeleteVersionMutation, useGetVersionsMutation } from '../../services/nodeApi';
import { Row } from 'antd';
import { DeleteFilled, DownloadOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CreateVersion from './Create';

function VersionList() {
	const [versions, setVersions] = useState([]);
	const [showVersionCreater, setVersionCreater] = useState(false);

	const [versionsList] = useGetVersionsMutation();
	const [versionDelete] = useDeleteVersionMutation();

	console.log(useGetVersionsMutation());

	// Fetch the list of uploaded versions when the component mounts
	async function fetchVersions() {
		try {
			const response = await versionsList(); // Replace with your API endpoint
			const v = [...response.data.data]
			console.table(v)
			setVersions(v); // Assuming the API returns an array of versions
		} catch (error) {
			console.error('Error fetching versions:', error);
		}
	}

	useEffect(() => {
		fetchVersions();
	}, []);

	const handleAddClick = () => {
		setVersionCreater(prev => !prev)
	}

	const deleteVersion = async (id) => {
		versionDelete(id).then(() => fetchVersions())

	}

	const onCreate = async()=>{
		setVersionCreater(false)
		fetchVersions()
	}

	return (
		<div style={{ paddingBlock: "1rem" }}>

			<Row style={{ justifyContent: "space-between", alignItems: "center" }} >
				<h2>Uploaded Versions</h2>
				{!showVersionCreater
					? <PlusCircleOutlined style={{ fontSize: "x-large" }} onClick={handleAddClick} />
					: <MinusCircleOutlined style={{ fontSize: "x-large" }} onClick={handleAddClick} />
				}
			</Row>

			{showVersionCreater && <CreateVersion onCreate={onCreate} />}

			<table className='table'>
				<tr >
					<th className='th' >Version Number</th>
					<th className='th' >Release Date</th>
					<th className='th' >Description</th>
					<th className='th' >Urgent</th>
					<th className='th' >Download Url</th>
				</tr>
				{(versions.length < 1) && "No Data..."}
				{versions.map((version) => (<>
					<tr >
						<td className='td' >{version.versionNumber}</td>
						<td className='td' >{version.releaseDate}</td>
						<td className='td' >{version.description}</td>
						<td className='td' >{
						version.urgent === true 
						? (<div className='bullet' style={{ backgroundColor: "green", }}></div>) 
						: (<div className='bullet' style={{ backgroundColor: "lightcoral" }}> </div>)}</td>
						<td className='td' >
							<a download className='link' href={version.downloadUrl}> <DownloadOutlined /> </a>
							<DeleteFilled style={{ margin: "0.5rem", padding: "0.25rem" }} onClick={() => deleteVersion(version._id)} />
						</td>
					</tr>
				</>))}
			</table>
		</div>
	);
}

export default VersionList;