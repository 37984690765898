import './App.css'
import Login from './components/Auth/Login'
import 'antd/dist/reset.css'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './components/Dashboard/Dashboard'
import ProtectedRoute from './components/ProtectedRoute'
import User from './components/User/User'
import Channel from './components/Channel/Channel'
import Event from './components/Event/Event'
import Scrapping from './components/Scrapping/Scrapping'
import AddChannel from './components/Channel/AddChannel'
import UpdateChannel from './components/Channel/UpdateChannel'
import AddEvent from './components/Event/AddEvent'
import UpdateEvent from './components/Event/UpdateEvent'
import Token from './components/Token/Token'
import AddToken from './components/Token/AddToken'
import UpdateToken from './components/Token/UpdateToken'
import ChannelCategories from './components/Channel/ChannelCategories'
import UpdateChannelCategories from './components/Channel/UpdateChannelCategories'
import UpdateEventCategories from './components/Event/UpdateEventCategories'
import EventCategories from './components/Event/EventCategories'
import ChannelCountries from './components/Channel/ChannelCountries'
import EventCountries from './components/Event/EventCountries'
import UpdateEventCountries from './components/Event/UpdateEventCountries'
import UpdateChannelCountries from './components/Channel/UpdateChannelCountries'
import CreateVersion from './components/Version/Create'
import VersionList from './components/Version/list'

function App () {
  return (
    <>
      <Routes>
        <Route
          exact
          path='/directory/221133/dashboard/'
          element={
            <ProtectedRoute role={['admin']}>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route exact path='' element={<User />} />
          <Route exact path='users' element={<User />} />
          <Route exact path='tokens' element={<Token />} />
          <Route
            exact
            path='channel/countries'
            element={<ChannelCountries />}
          />
          <Route exact path='event/countries' element={<EventCountries />} />
          <Route
            exact
            path='channel/categories'
            element={<ChannelCategories />}
          />
          <Route
            exact
            path='channel/categories/update'
            element={<UpdateChannelCategories />}
          />
          <Route exact path='event/categories' element={<EventCategories />} />
          <Route
            exact
            path='event/categories/update'
            element={<UpdateEventCategories />}
          />
          <Route
            exact
            path='event/countries/update'
            element={<UpdateEventCountries />}
          />
          <Route
            exact
            path='channel/countries/update'
            element={<UpdateChannelCountries />}
          />
          <Route exact path='tokens/add' element={<AddToken />} />
          <Route exact path='tokens/update' element={<UpdateToken />} />
          <Route exact path='channels' element={<Channel />} />
          <Route exact path='channels/add' element={<AddChannel />} />
          <Route exact path='channels/update' element={<UpdateChannel />} />
          <Route exact path='events' element={<Event />} />
          <Route exact path='events/add' element={<AddEvent />} />
          <Route exact path='events/update' element={<UpdateEvent />} />
          <Route exact path='scrapping' element={<Scrapping />} />
          <Route exact path='versions' element={<VersionList />} />
        </Route>
        <Route path='/directory/221133/login' element={<Login />} />
        <Route path='/directory/221133' element={<Login />} />
      </Routes>
    </>
  )
}

export default App
