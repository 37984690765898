import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import Header from '../Generic/Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useGetEventCountriesByIdQuery,
  useUpdateEventCountriesMutation
} from '../../services/nodeApi'

const UpdateEventCountries = () => {
  const { eventCountriesId } = useSelector(state => state.eventCountriesId)
  const [eventCountriesData, setEventCountriesData] = useState(null)
  let navigate = useNavigate()
  const [updateEventCountries] = useUpdateEventCountriesMutation()
  const { data } = useGetEventCountriesByIdQuery(eventCountriesId)
  const [form] = Form.useForm()

  const onFinish = async values => {
    let obj = { ...values, eventCountriesId: eventCountriesId }
    const res = await updateEventCountries(obj)
    if (res.data && res.data.status === 'success') {
      message.success('Event Category has been updated!')
      setTimeout( () => navigate( '/directory/221133/dashboard/event/countries' ), 1000 )
    } else {
      message.error('Something went wrong!, Please try again later')
    }
  }
  useEffect(() => {
    data && setEventCountriesData(data.data)
  }, [data])
  return (
    <>
      <Header heading='Edit Event Category' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        {eventCountriesData && (
          <Form
            name='update_event_Countries_form'
            form={form}
            layout='horizontal'
            onFinish={onFinish}
            initialValues={{
              ID: eventCountriesData.ID,
              name: eventCountriesData.name
            }}
          >
            <Form.Item
              name='ID'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input event category id!'
                }
              ]}
            >
              <Input type='Number' placeholder='Event category id' />
            </Form.Item>
            <Form.Item
              name='name'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input event category  name!'
                }
              ]}
            >
              <Input placeholder='Event category  name' disabled />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  paddingTop: '1.5rem'
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  Edit
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  )
}

export default UpdateEventCountries
