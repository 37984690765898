import { createSlice } from '@reduxjs/toolkit'

//******* Declare your state variable here
const initialState = {
  eventCategoriesId: null
}

export const eventCategoriesSlice = createSlice({
  name: 'eventCategoriesId',
  initialState,
  reducers: {
    setEventCategoriesId: (state, action) => {
      state.eventCategoriesId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setEventCategoriesId } = eventCategoriesSlice.actions

export default eventCategoriesSlice.reducer
