import React, { useState } from 'react';
import { useCreateVersionMutation } from '../../services/nodeApi';
import TextArea from 'antd/es/input/TextArea';
import Input from 'antd/es/input/Input';
import {Row} from 'antd';

function CreateVersion(props) {
  const [formData, setFormData] = useState({
    urgent: false,
    versionNumber: '',
    releaseDate: '',
    description: '',
    apkFile: null, // Initialize the APK file state
  });

	const [createVersion] = useCreateVersionMutation()

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    console.log("type: ", type)

    // If the input is a file input (apkFile), update the state with the selected file
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else if (type === "checkbox") {
      setFormData({...formData, [name]: checked});
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('versionNumber', formData.versionNumber);
    formDataToSend.append('releaseDate', formData.releaseDate);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('urgent', formData.urgent);
    formDataToSend.append('apkFile', formData.apkFile);

    try {
      console.table([formDataToSend,formData])
      // Make a POST request to your API endpoint
      const response = await createVersion(formDataToSend)
      props.onCreate()

      console.log(response.data);
      // Handle success, reset the form, show a success message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show an error message, etc.
    }
  };

  return (
    <div style={{paddingBlock: "1rem",}} >
      <h3>Create New Version</h3>
      <form method='POST' multipart onSubmit={handleSubmit}>
        <Row>
          <label htmlFor="urgentCheckBoxId">Urgent</label>
          <Input
            style={{width: "auto", marginInline: "1rem"}}
            type="checkbox"
            id="urgentCheckBoxId"
            name="urgent"
            checked={formData.urgent}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <label htmlFor="versionNumber">Version Number:</label>
          <Input
          placeholder='Version Number'
            type="text"
            id="versionNumber"
            name="versionNumber"
            value={formData.versionNumber}
            onChange={handleChange}
            required
          />
        </Row>
        <Row>
          <label htmlFor="releaseDate">Release Date:</label>
          <Input
            type="date"
            id="releaseDate"
            name="releaseDate"
            value={formData.releaseDate}
            onChange={handleChange}
            required
          />
        </Row>
        <Row>
          <label htmlFor="description">Description:</label>
          <TextArea
            rows={4}
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </Row>
        <Row>
          <label htmlFor="apkFile">APK File:</label>
          <Input
            type="file"
            id="apkFile"
            name="apkFile"
            onChange={handleChange}
            required
          />
        </Row>
        <button style={{marginBlock: "1rem"}} type="submit">Create Version</button>
      </form>
    </div>
  );
}

export default CreateVersion;