import React, { useState, useEffect } from 'react'
import { Table, Input, Row, Col, Modal, Tooltip, message } from 'antd'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
  useDeleteTokenMutation,
  useGetAllTokensQuery
} from '../../services/nodeApi'
import { Link } from 'react-router-dom'
import { setTokenId } from '../../redux/tokenSlice'
const { confirm } = Modal

const TokenTable = () => {
  const dispatch = useDispatch()
  const [deleteToken] = useDeleteTokenMutation()
  const { data, isLoading, isFetching } = useGetAllTokensQuery()
  const [tokenData, setTokenData] = useState(null)
  const [messageApi, contextHolder] = message.useMessage()
  const success = msg => {
    messageApi.open({
      type: 'success',
      content: msg
    })
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: '20%'
    },

    {
      title: 'Url',
      dataIndex: 'url',
      align: 'center',
      width: '40%'
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '40%',
      render: (_, record) => (
        <>
          <Tooltip title='Edit token'>
            <Link to={`/directory/221133/dashboard/tokens/update`}>
              <span
                onClick={() => dispatch(setTokenId(record._id))}
                style={{
                  fontSize: '1.3rem',
                  color: '#2596be',
                  cursor: 'pointer',
                  marginRight: '14px'
                }}
              >
                <EditOutlined />
              </span>
            </Link>
          </Tooltip>
          <Tooltip title='Delete token'>
            <span
              onClick={() => showDeleteConfirm(record)}
              style={{
                fontSize: '1.3rem',
                color: 'red',
                cursor: 'pointer'
              }}
            >
              <DeleteOutlined />
            </span>
          </Tooltip>
        </>
      )
    }
  ]
  const showDeleteConfirm = record => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you want to delete this token?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk () {
        await deleteToken(record._id)
        setTimeout(() => {
          success('Token has been deleted!')
        }, 1500)
      },
      onCancel () {}
    })
  }

  useEffect(() => {
    if (!isLoading && data && !isFetching) {
      setTokenData(data.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])
  useEffect(() => {}, [data])

  return (
    <>
      {contextHolder}
      {!isLoading && columns && (
        <Table columns={columns} dataSource={tokenData} size='middle' />
      )}
    </>
  )
}

export default TokenTable
