import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { nodeApi } from '../services/nodeApi'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import channelSlice from './channelSlice'
import eventSlice from './eventSlice'
import tokenSlice from './tokenSlice'
import channelCategoriesSlice from './channelCategoriesSlice.js'
import eventCategoriesSlice from './eventCategoriesSlice'
import channelCountriesSlice from './channelCountriesSlice'
import eventCountriesSlice from './eventCountriesSlice'

const reducers = combineReducers({
  [nodeApi.reducerPath]: nodeApi.reducer,
  channelId: channelSlice,
  eventId: eventSlice,
  tokenId: tokenSlice,
  channelCategoriesId: channelCategoriesSlice,
  eventCategoriesId: eventCategoriesSlice,
  channelCountriesId: channelCountriesSlice,
  eventCountriesId: eventCountriesSlice
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['nodeApi']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const apiStore = configureStore({
  reducer: persistedReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      nodeApi.middleware
    )
})

setupListeners(apiStore.dispatch)
