import React from 'react'
import { useAddTokenMutation } from '../../services/nodeApi'
import { Form, Input, Button, message } from 'antd'
import Header from '../Generic/Header'
import { useNavigate } from 'react-router-dom'

const AddToken = () => {
  let navigate = useNavigate()
  const [addToken] = useAddTokenMutation()
  const [form] = Form.useForm()

  const onFinish = async values => {
    values.id = Number(values.id)
    const res = await addToken(values)
    if (res.data && res.data.status === 'success') {
      message.success('Token has been added!')
      setTimeout( () => navigate( '/directory/221133/dashboard/tokens' ), 1000 )
    } else {
      message.error('Something went wrong!, Please try again later')
    }
  }

  return (
    <>
      <Header heading='Add Token' add_btn={false} />
      <div style={{ marginTop: '2rem' }}>
        <Form
          name='add_token_form'
          form={form}
          layout='horizontal'
          onFinish={onFinish}
        >
          <Form.Item
            name='id'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input token id!'
              }
            ]}
          >
            <Input type='number' placeholder='Token id' />
          </Form.Item>
          <Form.Item
            name='url'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input token url!'
              }
            ]}
          >
            <Input placeholder='Token url' />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                paddingTop: '1.5rem'
              }}
            >
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
              >
                Add
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default AddToken
